<template>
	<div class="content-wrapper">

		<bo-page-title v-if="isList" />
		<div v-if="isList" class="content pt-0">
			<div class="card">
				<div class="bg-white card-header">
					<div class="row align-items-center">
						<div class="col-md-4 col-lg-5">
							<div class="form-row align-items-center">
								<div class="col-lg-4">
									<h5 class="card-title font-weight-semibold">Daftar Pasien</h5>
								</div>
								<div class="col-lg-8">
									<div class="form-group mb-0">
										<div class="input-group">
											<date-range-picker ref="picker" :locale-data="datePickerConfig.locale"
												:autoApply="datePickerConfig.autoApply" v-model="dateRange" :opens="'right'"
												:ranges="datePickerConfig.ranges" @update="updateValues">
												<template v-slot:input="picker">
													{{ picker.startDate | date }} - {{ picker.endDate | date }}
												</template>
											</date-range-picker>
											<div class="input-group-append calendar-group">
												<span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-6 col-lg-3">
							<b-form-group class="mb-0">
								<v-select placeholder=" -- Pilih Status -- " @input="doFill" v-model="filter.status_ranap" :options="Config.mr.statusRanap" label="text" :reduce="v=>v.value" :clearable="true" />
							</b-form-group>
						</div>
					</div>
				</div>

				<div class="card-header">
					<div class="row">
						<div class="col-md-8 col-lg-9">
							<div class="row gx-1">
								<div class="col-md-auto">
									<div class="form-group mb-0">
										<button @click="doRefreshData" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover.right><i class="icon-spinner11"></i></button>
									</div>
								</div>
								<div class="col-md">
									<div class="row g-1">
										<div class="col-md-6 col-lg-3">
											<b-form-group class="mb-0">
												<v-select placeholder=" -- Pilih Jaminan -- " @input="doFill" v-model="filter.status_jaminan"
													:options="Config.mr.statusJaminan" label="text" :reduce="v=>v.value"></v-select>
											</b-form-group>
										</div>
										<div class="col-md-6 col-lg-3">
											<b-form-group class="mb-0">
												<v-select placeholder=" -- Pilih Cara Masuk -- " @input="doFill" v-model="filter.cara_masuk"
													:options="Config.mr.caraMasuk" label="text" :reduce="v=>v.value"></v-select>
											</b-form-group>
										</div>
										<div class="col-md-6 col-lg-3">
											<b-form-group class="mb-0">
												<v-select placeholder=" -- Pilih Bangsal -- " @input="doFill" v-model="filter.ranap_bangsal"
													:options="mRanapBangsal" label="text" :reduce="v=>v.value"></v-select>
											</b-form-group>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-4 col-lg-3">
							<div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
								<input class="form-control" v-model="filter.search" @input="doSearch"
									placeholder="Ketik Nama/No. RM Pasien" />
								<div class="form-control-feedback">
									<i class="icon-search4 text-indigo"></i>
								</div>

								<b-button class="ml-1 d-inline-flex align-items-center" variant="outline-success" id="resetBtn"
									@click="doResetData()">Reset</b-button>
							</div>
						</div>
					</div>
				</div>



				<div class="table-responsive">
					<table class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
						<thead>
							<tr>
								<th>NO.</th>
								<th class="tbl-sticky-col tsc-left">NO. KEDATANGAN</th>
								<th>TANGGAL MASUK</th>
								<th class="tbl-sticky-col tsc-left tsc-left-last" style="--tsc-pos: 115px;">NAMA/NO KTP</th>
								<th>DOKTER DPJP</th>
								<th>NO RM</th>
								<th>RUANGAN</th>
								<th>JENIS RAWAT INAP</th>
								<th>TANGGAL LAHIR</th>
								<th>USIA</th>
								<th>PPJP</th>
								<th>STATUS</th>
								<th style="min-width: 100px;" class="text-center tbl-sticky-col tsc-right tsc-right-last">AKSI</th>
							</tr>
						</thead>

						<tbody v-if="(dataList||[]).length">
							<template v-for="(v,k) in (dataList||[])">
								<tr :key="k"
									:class="validationTime(v.aranr_reg_date) && v.aranr_have_kajian_awal_dietisien != 'Y' ? 'table-warning':''">
									<td>{{(data.per_page*(data.current_page-1))+k+1}}</td>
									<td class="tbl-sticky-col tsc-left">
										<div class="text-success-700 font-weight-semibold">{{v.aranr_reg_code||"-"}}</div>
									</td>
									<td>
										<div>{{v.aranres_tanggal_periksa | moment("DD MMMM YYYY")}}</div>
									</td>
									<td class="tbl-sticky-col tsc-left tsc-left-last" style="--tsc-pos: 115px;">
										<div>
											<a href="javascript:;" @click="toMonitoring(v.aranr_id)"
												class="font-weight-semibold border-bottom" v-b-tooltip.hover title="Periksa Pasien">
												{{v.ap_fullname||"-"}}</a>
											<br>
											{{v.ap_code||"-"}}</div>
										<small class="text-secondary">Terdaftar {{v.aranr_reg_date | moment("DD MMMM YYYY, HH:mm")}}</small>
									</td>
									<td>{{v.bu_full_name||"-"}}</td>
									<td>
										<div class="font-weight-semibold">{{v.ap_code||"-"}}</div>
										<span>dari
											{{v.aranres_cara_masuk == 'RAJAL'?'POLI': v.aranres_cara_masuk == 'BBL' ? 'BBL':'UGD'}}</span>
										<div class="text-success-700 font-weight-semibold">
											{{v.no_reg||"-"}}
										</div>
									</td>
									<td>
										<div class="font-weight-semibold">{{v.mrank_name||"-"}}</div>
										<small><span>{{v.mranb_name||"-"}}</span> - <span>{{v.mrankel_name||"-"}}</span></small>
										<span class="text-secondary" v-if="v.mranbed_name"> - {{v.mranbed_name || "-"}}</span>
									</td>
									<td>
										<div class="font-weight-semibold">
											{{getConfigDynamic(Config.mr.jenisRawatInap,v.aranres_jenis_ranap)||"-"}}</div>
									</td>
									<td>
										<div class="font-weight-semibold" v-if="v.ap_dob">{{v.ap_dob | moment("DD MMMM YYYY") }}</div>
										<span v-else> - </span>
									</td>
									<td>
										<div class="font-weight-semibold">
											{{v.ap_usia_with_ket || "-"}}
										</div>
									</td>
									<td>
										{{v.nama_ppjp||"-"}}
									</td>
									<td>
										<div v-if="!v.aranr_ppjp && !v.aranr_perawat_pelaksana" class="badge badge-warning mb-1">Tugaskan
											PPJP</div>

										<div v-if="v.is_draft == 'Y'" class="badge badge-warning mb-1 pl-3 pr-3">Draft</div>

										<div v-else-if="v.aranr_ppjp && !v.aranr_perawat_pelaksana" class="badge badge-warning mb-1">
											Tugaskan Perawat Pelaksana</div>
										<template v-else>
											<div :class="`badge ${v.arans_color} mb-1`">{{v.arans_name||"-"}}</div>

											<template v-if="v.aranr_status == 1">
												<div v-if="v.aranr_is_done_perawat != 'Y'" class="badge badge-warning mb-1">Pengkajian Awal
													Perawat</div>

												<div v-if="v.aranr_is_done_dokter != 'Y'" class="badge badge-warning mb-1">Pengkajian Awal
													Dokter</div>
											</template>

										</template>


									</td>
									<td class="tbl-sticky-col tsc-right tsc-right-last">
										<a href="javascript:;" @click="doOpenRiwayatKajian(v)" v-if="moduleRole('lihat_dokumen_kajian')"
											class="btn btn-icon rounded-round btn-sm alpha-info border-info text-info-800"
											data-popup="tooltip" v-b-tooltip.hover.right title="Lihat Dokumen Kajian">
											<i class="icon-file-text2"></i>
										</a>

										<template>
											<!-- kajian perawat -->
											<template v-if="moduleRole('buat_kajian')">
												<a href="javascript:;" v-if="v.aranr_have_kajian_awal_dietisien != 'Y'"
													@click="initKajianAwal(v)"
													class="btn btn-icon rounded-round btn-sm alpha-indigo border-indigo text-indigo-800"
													v-b-tooltip.hover.right title="Tambahkan Kajian Awal Gizi">
													<i class="fas fa-notes-medical"></i>
												</a>

												<a href="javascript:;" v-if="v.aranr_have_kajian_awal_dietisien == 'Y'" @click="lihatKajian(v)"
													class="btn btn-icon rounded-round btn-sm alpha-indigo border-indigo text-indigo-800"
													v-b-tooltip.hover.right :title="v.isEdit ? 'Edit Kajian Awal Gizi' : 'Isi Kajian Awal Gizi'">
													<i class="icon-pencil7"></i>
												</a>
											</template>
											<!-- kajian perawat -->
										</template>

										<div v-if="validationTime(v.aranr_reg_date) && v.aranr_have_kajian_awal_dietisien != 'Y'"
											v-b-tooltip.hover="'Anda telah melewati batas waktu pengisian Kajian Awal Gizi (1x24 jam)'"
											class="badge badge-danger mb-1">Batas Waktu Kajian Awal</div>
									</td>

								</tr>
							</template>
						</tbody>
						<tbody v-if="data.data.length == 0">
							<tr>
								<td colspan="99">
									<div class="text-center">
										<h4 align="center"><span
												v-if="filter.status || filter.search || filter.startDate || filter.endDate">Hasil pencarian
												tidak ditemukan</span>
											<span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
									</div>
								</td>
							</tr>
						</tbody>
						<tbody v-if="!data.data">
							<tr>
								<td colspan="99">
									<div class="skeletal-comp"></div>
								</td>
							</tr>
							<tr>
								<td colspan="99">
									<div class="skeletal-comp"></div>
								</td>
							</tr>
							<tr>
								<td colspan="99">
									<div class="skeletal-comp"></div>
								</td>
							</tr>
						</tbody>
					</table>


					<div
						:class="(dataList||[]).length && !loadingTable && dataList ? 'table-scroll-actions' : 'table-scroll-actions d-none'">
						<a href="javascript:;" data-scroll="left"
							class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
							<i class="icon-chevron-left"></i>
						</a>
						<a href="javascript:;" data-scroll="right"
							class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
							<i class="icon-chevron-right"></i>
						</a>
					</div>
				</div>

				<b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">
					<b-pagination class="mb-0" v-model="pageNo" :per-page="data.per_page" :total-rows="data.total" />
				</b-card-footer>

			</div>

			<validation-observer ref="VFormSubmitPerawatPelaksana">
				<b-modal v-model="openPerawatPelaksana" @ok.prevent="submitPerawatPelaksana" size="lg" no-close-on-backdrop
					class="text-center" :title="'Tugaskan Perawat Pelaksana'">
					<table class="table table-sm table-bordered">
						<thead>
							<tr>
								<th colspan="6">Informasi Pasien</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td colspan="2">
									<div class="result_tab">
										<h4>Nama</h4>
										<p>{{rowEdit.ap_fullname||"-"}}</p>
									</div>
								</td>
								<td colspan="2">
									<div class="result_tab">
										<h4>Tanggal Lahir / Umur</h4>
										<p>{{rowEdit.ap_dob | moment("DD MMMM YYYY") }} / {{rowEdit.ap_usia}} Tahun</p>
									</div>
								</td>
								<td colspan="2">
									<div class="result_tab">
										<h4>Jenis Kelamin</h4>
										<p>{{rowEdit.ap_gender == 1 ? "Laki-Laki":"Perempuan"}}</p>
									</div>
								</td>
							</tr>
							<tr>
								<td colspan="2">
									<div class="result_tab">
										<h4>Ruangan</h4>
										<p>{{rowEdit.mranb_name||"-"}}</p>
									</div>
								</td>
								<td colspan="2">
									<div class="result_tab">
										<h4>Kamar / No. Tempat Tidur / Kelas</h4>
										<p>{{rowEdit.mranbed_name||"-"}} / {{rowEdit.mrankel_name||"-"}}</p>
									</div>
								</td>
								<td colspan="2">
									<div class="result_tab">
										<h4>Kelas Titipan</h4>
										<p>-</p>
									</div>
								</td>
							</tr>
							<tr>
								<td colspan="2">
									<div class="result_tab">
										<h4>Jenis Cara Bayar</h4>
										<p>{{getConfigDynamic(Config.mr.statusJaminan,rowEdit.aranres_jaminan)||"-"}}</p>
									</div>
								</td>
								<td colspan="2">
									<div class="result_tab">
										<h4>Alergi</h4>
										<ul v-if="(rowEdit.alergi||[]).length" class="pl-3">
											<li v-for="(v,k) in (rowEdit.alergi||[])" :key="k+'alergi'">
												{{v.name||"-"}} - <span class="font-weight-semibold">{{v.jenis||"-"}}</span>
											</li>
										</ul>
										<span v-else>Tidak ada Alergi</span>
									</div>
								</td>
								<td colspan="2">
									<div class="result_tab">
										<h4>Diagnosa Masuk</h4>
										<p>{{rowEdit.diagnosa||"-"}}</p>
									</div>
								</td>
							</tr>
						</tbody>

						<thead>
							<tr>
								<th colspan="6">Penugasan Perawat Pelaksana</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td colspan="3" width="50%">
									<div>
										<label for="selPppjp">Pilih Perawat Penanggung jawab Pasien (PPJP)</label>
										<v-select placeholder=" -- Pilih Perawat Penanggung jawab Pasien -- " 
										v-model="rowEdit.aranr_ppjp" :clearable="true" :options="mPPJP" label="text" :reduce="v=>v.value">
												<template slot="selected-option" slot-scope="option">
												<span :title="option.text+' - menangani '+option.pasien + ' Pasien'" v-b-tooltip.hover.right>{{ option.text }}</span>
												</template>
												<template slot="option" slot-scope="option">
												<span :title="option.text+' - menangani '+option.pasien + ' Pasien'" v-b-tooltip.hover.right>{{ option.text }} - menangani 
														<span class="font-weight-semibold"> {{option.pasien}} Pasien </span></span>
												</template>
										</v-select>
									</div>
									<VValidate :name="'Perawat Penanggung Jawab Pasien'" v-model="rowEdit.aranr_perawat_pelaksana"
										:rules="{required : 1}" />
								</td>
								<td colspan="3" width="50%">
									<div></div>
								</td>
							</tr>
						</tbody>
					</table>
				</b-modal>
			</validation-observer>

		</div>

		<b-modal v-model="isOpenModalHistory" :title="'Riwayat Rekam Medis'" size="xl" ok-only ok-title="Tutup">
			<b-tabs nav-class="nav-tabs-bottom nav-justified mt-2 mb-0">
				<b-tab title="UGD">
					<HistoryUGD :dataProps="dataUGD" :pasienId="rowEdit.aranr_ap_id" />
				</b-tab>
				<b-tab title="Rawat Jalan">
					<HistoryRawatJalan :dataProps="dataPoli" :pasienId="rowEdit.aranr_ap_id" />
				</b-tab>
			</b-tabs>
		</b-modal>

	</div>
</template>

<script>
    import GlobalVue from '@/libs/Global.vue'
    import DateRangePicker from 'vue2-daterange-picker'
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

    const moment = require('moment')
    
    // import Datepicker from 'vuejs-datepicker'

    import HistoryUGD from '@/components/Ranap/HistoryUGD.vue'
    import HistoryRawatJalan from '@/components/Ranap/HistoryRawatJalan.vue'

    const _ = global._
    import $ from 'jquery'
    import Gen from '@/libs/Gen.js'

    export default {
        extends: GlobalVue,
  	    components:{
            DateRangePicker,
            HistoryUGD,
            HistoryRawatJalan
        },
        computed:{
            isDetail(){
                return this.$route.query.isDetail
            },
            isDoc(){
                return this.$route.query.isDoc
            },
            passToSub(){
                return {
                    Config:this.Config,
                    mrValidation: this.mrValidation,
                    rowPasien: this.rowPasien,
                    isShowBangsal: this.isShowBangsal,
                    infoBangsal: this.infoBangsal,
                    katalogBangsal: this.katalogBangsal
                }
            },
        },
        data(){
            return {
                idKey:'aranres_id',
                datePickerConfig: {
                    startDate: new Date(moment().subtract(6, 'days')),
                    endDate: new Date(),
                    autoApply: true,
                    ranges: {
                        'Hari Ini': [new Date(), new Date()],
                        '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                        '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
                    },
                    applyClass: 'btn-sm btn-primary',
                    cancelClass: 'btn-sm btn-light',
                    locale: {
                        applyLabel: 'Terapkan',
                        cancelLabel: 'Batal',
                        direction: 'ltr',
                        format: 'mm/dd/yyyy',
                        separator: ' - ',
                    }
                },
                dateRange: {
                    startDate: new Date(moment().subtract(6, 'days')),
                    endDate: new Date(),
                },

                rowEdit : {},
                rowPasien : {},
                rowAdditional: {},
                mRanapBangsal : [],
                mPPJP: [],
                openPerawatPelaksana: false,

                isOpenModalHistory: false,
                dataPoli: {},
                dataUGD: {}
            }
        },
        mounted(){
            this.filter.status_jaminan = 'ALL'
            this.filter.cara_masuk = 'ALL'
            this.filter.ranap_bangsal = this.user.bu_bangsal_id
            this.filter.status_ranap = 2
            
            this.doConvertDate()      
            this.apiGet()
            
            // if(this.isList){
            //     this.apiGetResume()
            // }
                
            setTimeout(()=>{
                this.setSlide()
            },1000)
        },
        methods: {
            
            doOpenRiwayatKajian(row){
                this.rowEdit = row
                this.loadingOverlay = true
                Gen.apiRest(
                    "/do/" + 'RanapPasienPerawatan', {
                    data: {
                        type: "get-riwayat-poli",
                        id: this.rowEdit.aranr_ap_id
                    }
                    },
                    "POST"
                ).then(res => {
                    let resp = res.data
                    this.dataPoli = resp.dataPoli
                })

                Gen.apiRest(
                    "/do/" + 'RanapPasienPerawatan', {
                    data: {
                        type: "get-riwayat-ugd",
                        id: this.rowEdit.aranr_ap_id
                    }
                    },
                    "POST"
                ).then(res => {
                    let resp = res.data
                    this.loadingOverlay = false
                    this.isOpenModalHistory = true
                    this.dataUGD = resp.dataUGD
                })
            },

            toMonitoring(regID){
                this.$router.push({ name: 'RanapMonitoring', params: {pageSlug: regID}}).catch(()=>{})
            },
            setSlide(){
                const responsiveTable = document.querySelector('.table-responsive')
                if(responsiveTable){
                    const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
                    const responsiveTableScrollActions = responsiveTable.querySelectorAll('.btn')
                    if(responsiveTableScroll){
                        // responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                        Array.from(responsiveTableScrollActions).forEach(action => {
                            action.addEventListener('click', e => {
                                const scrollAmount = action.dataset.scroll == 'right' ? responsiveTable.scrollLeft + 100 : responsiveTable.scrollLeft - 100
                                 
                                responsiveTable.scroll({
                                    left: scrollAmount,
                                    behavior: 'smooth'
                                })
                            })
                        })
                    }
                }
            },
            doResetData(){
                this.doReset()
                this.dateRange =  {
                    startDate: new Date(moment().subtract(6, 'days')),
                    endDate: new Date(),
                }
                this.doConvertDate()
            },
            doRefreshData(){
                this.apiGet()
                window.scrollTo(0,0)
            },
            apiGetResume(){
                let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
                Gen.apiRest(
                    "/get/"+this.modulePage+'/resume', 
                    {
                    params: Object.assign({}, paramsQuery||{})
                    }
                ).then(res=>{
                    _.forEach(res.data, (v,k)=>{
                        this.$set(this, k, v)
                    })
                })
            },
            doConvertDate(){
                this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
                this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
            },
            doPageOne(){
                this.filter.page = 1
            },
            doFill(){
                this.doPageOne()
                this.doFilter()
            },
            updateValues(e){
                this.doConvertDate()
                this.doFill()
            },
            doSearch: _.debounce(function(){
                this.doPageOne()
                this.doFilter()
            },500),
            getConfigDynamic(master,value){
                let text = ''
                if(value){
                    let index = (master||[]).findIndex(x => x.value == value)
                    if(index !== -1){
                        text = master[index]['text']
                    }
                }
                return text
            },

            validationTime(regDate){
                if(moment().format('YYYY-MM-DD') >= this.to24Hours(regDate)){
                    return true
                }else{
                    return false
                }
            },

            to24Hours(date){
                return moment(date).add(48, 'hours').format('YYYY-MM-DD')
            },
        
            initKajianAwal(row){
                this.createKajianAwal(row)                    
            },  
            
            createKajianAwal(row){
                this.loadingOverlay = true
                let data = {
                    idPasien : row.aranr_ap_id,
                    regId : row.aranr_id,
                    dokterId : row.aranr_dokter_id,
                    dokterRaber : row.aranr_dokter_raber_id,
                    jenisRawatInap : row.aranr_jenis_ranap
                }
                data.type = 'init-kajian-awal'
                Gen.apiRest(
                    "/do/"+this.modulePage,
                    {data: data}, 
                    "POST"
                ).then(res=>{
                    let resp = res.data
                    this.loadingOverlay = false
                    this.$router.push({ name: resp.menuKajian, params: {pageSlug: row.ap_id, rmNo: resp.aranrm_id}, query: {regId: row.aranr_id} }).catch(()=>{})
                })
            },

            lihatKajian(row){
                this.$router.push({ name: 'RanapKAGizi', params: {pageSlug: row.ap_id, rmNo: row.dietisien_id}, query: {regId: row.aranr_id} }).catch(()=>{})
            }
        },
        filters: {
            date(val) {
                return val ? moment(val).format("D MMM YYYY") : ""
            }
        },
        watch:{
            $route(){
                this.apiGet()
                
                // if(this.isList){
                //     this.apiGetResume()
                // }
                
                setTimeout(()=>{
                    if(this.isList){
                        this.setSlide()
                    }
                },1000)
            },
            '$route.params.pageSlug':function(){
                this.$set(this,'filter',{})
            },
            'openPerawatPelaksana':function(v){
                if(!v){
                    this.apiGet()
                }
            }
        }
    }
</script>